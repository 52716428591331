import { createSelector } from "@reduxjs/toolkit";
import camelCase from "lodash.camelcase";

import { AdaApiSlice } from "slices";

interface CoachingResponse {
  id: string;
  conversation_id: string;
  created: Date;
  created_by: string;
  generative_actions_event_id: string;
  intent: string;
  intent_type: "free_text" | "generated";
  is_live: boolean;
  text: string;
  updated: Date;
  updated_by: string;
}

interface CoachingReport {
  columnNames: string[];
  data: string[][];
}

export interface Coaching {
  id: string;
  conversationId: string;
  created: Date;
  /** User id */
  createdBy: string;
  generativeActionsEventId: string;
  intent: string;
  intentType: "free_text" | "generated";
  isLive: boolean;
  text: string;
  updated: Date;
  /** User id */
  updatedBy: string;
  conversations: number;
}

const transformResponse = (data: CoachingResponse): Coaching =>
  Object.entries(data).reduce(
    (obj, [k, v]) => {
      let val = v;

      if (["created", "updated"].includes(k)) {
        val *= 1000;
      }

      return { ...obj, [camelCase(k)]: val };
    },
    { conversations: 0 } as Coaching,
  );

const AdaApiSliceWithTags = AdaApiSlice.enhanceEndpoints({
  addTagTypes: ["Coaching"],
});

const coachingApi = AdaApiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCoachings: builder.query({
      query: () => ({
        url: "/coaching/",
        method: "GET",
      }),
      transformResponse: (response: { data: CoachingResponse[] }) =>
        response.data.map(transformResponse),
      providesTags: ["Coaching"],
    }),
    getCoaching: builder.query({
      query: (id: string) => ({
        url: `/coaching/${id}/`,
        method: "GET",
      }),
      transformResponse: (response: { data: CoachingResponse }) =>
        transformResponse(response.data),
      providesTags: ["Coaching"],
    }),
    getCoachingByConversationId: builder.query({
      query: (id: string) => ({
        url: `/coaching/conversation/${id}/`,
        method: "GET",
      }),
      transformResponse: (response: { data: CoachingResponse[] }) =>
        response.data.map(transformResponse),
      providesTags: ["Coaching"],
    }),
    getCoachingReport: builder.query({
      query: ({ params }) => ({
        url: "ares/tabular/reports/coaching/",
        method: "GET",
        params,
      }),
      transformResponse: (response: CoachingReport) => response,
    }),
    testCoaching: builder.mutation({
      query: (data) => ({
        url: "/coaching/evaluate",
        method: "POST",
        data,
      }),
      transformResponse: (response: { data: { message: string } }) =>
        response.data,
    }),
    createCoaching: builder.mutation({
      query: (data) => ({
        url: "/coaching/",
        method: "POST",
        data,
      }),
      transformResponse: (response: { data: CoachingResponse }) =>
        transformResponse(response.data),
      invalidatesTags: ["Coaching"],
    }),
    updateCoaching: builder.mutation({
      query: (data) => ({
        url: `/coaching/${data.id}`,
        method: "PUT",
        data,
      }),
      transformResponse: (response: { data: CoachingResponse }) =>
        response.data,
      invalidatesTags: ["Coaching"],
    }),
    deleteCoaching: builder.mutation({
      query: (id: string) => ({
        url: `/coaching/${id}/`,
        method: "DELETE",
      }),
      transformResponse: (response: { data: CoachingResponse }) =>
        response.data,
      invalidatesTags: ["Coaching"],
    }),
  }),
});

export const SelectAllCoachings = createSelector(
  coachingApi.endpoints.getCoachings.select({}),
  (res) => res.data ?? [],
);

export const {
  useGetCoachingsQuery,
  useGetCoachingQuery,
  useGetCoachingByConversationIdQuery,
  useGetCoachingReportQuery,
  useTestCoachingMutation,
  useCreateCoachingMutation,
  useUpdateCoachingMutation,
  useDeleteCoachingMutation,
} = coachingApi;
